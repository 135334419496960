/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Button, Text, Subtitle, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1co2udo css-42e4bw --style3 --full --parallax" anim={""} name={"uvod"} animS={"5"} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/790a04f9cf4948a6942b37bf04ee76ae_bl=1_ove=000000x25__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/790a04f9cf4948a6942b37bf04ee76ae_bl=1_ove=000000x25__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/790a04f9cf4948a6942b37bf04ee76ae_bl=1_ove=000000x25__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/790a04f9cf4948a6942b37bf04ee76ae_bl=1_ove=000000x25__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/790a04f9cf4948a6942b37bf04ee76ae_bl=1_ove=000000x25__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/790a04f9cf4948a6942b37bf04ee76ae_bl=1_ove=000000x25__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/790a04f9cf4948a6942b37bf04ee76ae_bl=1_ove=000000x25__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/790a04f9cf4948a6942b37bf04ee76ae_bl=1_ove=000000x25__s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Image src={"https://cdn.swbpg.com/t/25609/9d86150640434671856e2a21f54f3db0_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":126}} srcSet={"https://cdn.swbpg.com/t/25609/9d86150640434671856e2a21f54f3db0_s=350x_.png 350w, https://cdn.swbpg.com/t/25609/9d86150640434671856e2a21f54f3db0_s=660x_.png 660w, https://cdn.swbpg.com/t/25609/9d86150640434671856e2a21f54f3db0_s=860x_.png 860w, https://cdn.swbpg.com/t/25609/9d86150640434671856e2a21f54f3db0_s=1400x_.png 1400w, https://cdn.swbpg.com/t/25609/9d86150640434671856e2a21f54f3db0_s=2000x_.png 2000w"}>
              </Image>

              <Title className="title-box title-box--style2 title-box--center fs--86 lh--1 mt--08" style={{"maxWidth":750}} content={"<span style=\"color: var(--color-custom-2);\">Moderní chalupa<br>Vojtěchov</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 fs--14" use={"page"} href={"/fotografie"} content={"Prohlédnout pokoje"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--50" name={"[[UNIsecntioname12]]-1"}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--2 flex--center" anim={"4"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":692}} content={"Užijte si klid a pohodu ve Vojtěchově"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":525,"paddingRight":5}} content={"Možnost pronájmu chalupy již od minimálně&nbsp;<span style=\"font-weight: bold;\">3&nbsp;nocí, nebo víkend (pá-ne nebo čt -ne).</span><br>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":525,"paddingRight":0}} content={"Chalupa má dispozici 5+kk a nachází se ve Vojtěchově u Lísku.\nChalupu si zamilují milovníci přírody, protože se nachází v&nbsp;malebné krajině CHKO Žďárské vrchy.&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":1019}}>
              
              <Image className="--shape3" src={"https://cdn.swbpg.com/t/2124/41c30c8e51de4ca29eda3030957dc867_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/2124/41c30c8e51de4ca29eda3030957dc867_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/41c30c8e51de4ca29eda3030957dc867_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/41c30c8e51de4ca29eda3030957dc867_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/41c30c8e51de4ca29eda3030957dc867_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2124/41c30c8e51de4ca29eda3030957dc867_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--50" name={"[[UNIsecntioname12]]-2"} style={{"backgroundColor":"var(--color-custom-1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim5 --anim-s3 --center el--2 flex--center" anim={"5"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image className="--shape3" src={"https://cdn.swbpg.com/t/2124/18818640b34f4ab7acc79965414447a6_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/2124/18818640b34f4ab7acc79965414447a6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/18818640b34f4ab7acc79965414447a6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/18818640b34f4ab7acc79965414447a6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/18818640b34f4ab7acc79965414447a6_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2124/18818640b34f4ab7acc79965414447a6_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box fs--62 ls--006" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-custom-2);\">Odpočiňte si a načerpejte energii z přírody</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-custom-2);\">Moderní designová chalupa poskytne komfort i pro náročné zájemce.&nbsp;\nPlně vybavená kuchyně, romantický krb a prostorná společenská jídelna Vám zajistí příjemné chvíle v&nbsp;interiéru. Krásná zahrada s&nbsp;ovocnými stromy a keři, včetně zakryté terasy s posezením a venkovním ohništěm, Vám umožní naleznout vnitřní klid a rovnováhu.&nbsp;&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"d8lidrenvbu"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image src={"https://cdn.swbpg.com/o/2124/5b4e94212089463b9fbd2dbd2fa0e758.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":80}} srcSet={""}>
              </Image>

              <Subtitle className="subtitle-box w--500" content={"Wi-Fi <br>Zdarma"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image src={"https://cdn.swbpg.com/o/2124/3eb746d2878c4223808023c743ae1c54.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":80}} srcSet={""}>
              </Image>

              <Subtitle className="subtitle-box w--500" content={"Parkování<br>před chalupou&nbsp;"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image src={"https://cdn.swbpg.com/o/2124/92774249e59d4c06986f84ffb58168c5.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":80}} srcSet={""}>
              </Image>

              <Subtitle className="subtitle-box w--500" content={"Ubytování až<br>pro 12 osob<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image src={"https://cdn.swbpg.com/o/2124/61531709bc3a4b7cbcede66fe8c820e5.svg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":80}} srcSet={""}>
              </Image>

              <Subtitle className="subtitle-box w--500" style={{"maxWidth":677}} content={"Koupelna a WC na každém patře<br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-2blh70 css-hu074f pb--25 pt--30" name={"kontakt"} layout={"l1"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/33df266169344681a7b62596ba394498_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/33df266169344681a7b62596ba394498_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/33df266169344681a7b62596ba394498_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/33df266169344681a7b62596ba394498_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/33df266169344681a7b62596ba394498_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/33df266169344681a7b62596ba394498_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/33df266169344681a7b62596ba394498_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/2124/33df266169344681a7b62596ba394498_s=3000x_.jpg);
    }
  
background-position: 50% 89%;
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1" anim={"7"} animS={"3"} style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="pb--20 pt--20">
              
              <Title className="title-box fs--62 lh--14 mt--08" style={{"maxWidth":1276}} content={"<span style=\"color: rgb(255, 255, 255);\"><span style=\"background-color: rgb(30, 30, 31);\">&nbsp;Moderní chalupa&nbsp;</span><br></span><span style=\"background-color: rgb(255, 255, 255);\">&nbsp;úchvatná příroda&nbsp;</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 fs--14" content={"Prohlédnout okolí"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"8wfw4kue34n"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"14"} place={"49.56676815759235, 16.15939491378662"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--30 pt--25" name={"paticka"} style={{"backgroundColor":"rgba(21,21,22,1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2" anim={"2"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":560}}>
              
              <Subtitle className="subtitle-box fs--20 w--600" content={"<span style=\"color: var(--color-custom-2);\">Chalupavojtechov.cz</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-2);\">Vojtěchov č. ev. 107<br>593 01 Lísek</span><span style=\"color: var(--color-custom-2);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--20 lh--16" content={"<br><span style=\"color: var(--color-custom-2);\">+420 774 344 014<br>+420 775 967 530<br><a href=\"mailto:nikola@chalupavojtechov.cz?subject=Zpr%C3%A1va+z+webu\">nikola@chalupavojtechov.cz</a><br><a href=\"https://chalupavojtechov.cz\" style=\"\">www.chalupavojtechov.cz</a></span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}